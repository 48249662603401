import "./Project.scss";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useState } from "react";
import sudhirapp from "../../Assates/Project/sudhirapp.png";
import sudhirweb from "../../Assates/Project/sudhir.png";
import coder from "../../Assates/Project/coderdeck.png";
import engric from "../../Assates/Project/energic.png";
import sunity from "../../Assates/Project/sunity1.png";
import sunity1 from "../../Assates/Project/sunity2.png";
import sunity2 from "../../Assates/Project/sunity3.png";
import sunity3 from "../../Assates/Project/sunity4.png";
import sudhir1 from "../../Assates/Project/sudhir1.png";
import sudhir2 from "../../Assates/Project/sudhir2.png";

function Project() {
  const [imgData, setImgData] = useState([
    {
      img: sudhirapp,
      link: 'https://www.sudhirbastralaya.in/'
    },
    {
      img: sudhirweb,
      link: 'https://www.sudhirbastralaya.in/'
    },

    {
      img: sunity2,
      link: 'https://newsunityjewellers.in/'
    },
    {
      img: sudhir2,
      link: 'https://www.sudhirbastralaya.in/'
    }, {
      img: sudhir1,
      link: 'https://www.sudhirbastralaya.in/'
    },
    {
      img: sunity3,
      link: 'https://newsunityjewellers.in/'
    },
    {
      img: coder,
      link: 'https://techaquarius.com/'
    },
    {
      img: engric,
      link: 'https://www.energiccropscience.com/'
    },
    {
      img: sunity,
      link: 'https://newsunityjewellers.in/'
    },
    {
      img: sunity1,
      link: 'https://newsunityjewellers.in/'
    },
  ]);

  return (
    <div className="project-component" id="projects">
      <div className="project-container">
        <div className="project-top-container">
          <p className="head">Project</p>
          <h3 className="tags">Few of Our Popular Design</h3>
          <p className="para">
            Stylish and Responsive Website as per our client's requirement,Best
            website to manage heavy traffic.
          </p>
        </div>

        <div className="project-buttom-container">
          <ImageList sx={{ width: "100%" }} variant="masonry" cols={3} gap={8}>
            {imgData.map((item, index) => (
              <a href={item.link} target="_blank">
                <ImageListItem key={index} className="img-box">
                  <img
                    srcSet={`${item}?w=161&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=161&fit=crop&auto=format`}
                    alt={`aspire world tech project - ${item.img}`}
                    loading="lazy"
                  />
                </ImageListItem>
              </a>
            ))}
          </ImageList>
        </div>
      </div>
    </div>
  );
}

export default Project;
