import './ParalaxComonent.scss';
import img1 from '../../Assates/Element/banner1.png';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from 'sweetalert2';
import { addNewsletterEmail } from '../../Firebase/firebase';
import { useState } from 'react';
const schema = yup
  .object({
    email: yup
      .string()
      .required("Email is a required field")
      .email("Must be a valid email"),
  })
  .required();

function ParalaxComonent() {
    const [loader, setLoader] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm({ resolver: yupResolver(schema) });

      const onSubmit = async (data) => {
        setLoader(true)
        const result = await addNewsletterEmail(data.email);
        if (result.success) {
          Swal.fire({
            title: "Thank You",
            text: result.message,
            icon: "success",
          });
          reset();
        } else {
          Swal.fire({
            title: "Opps... Sorry!",
            text: result.message,
            icon: "error",
          });
        }
        setLoader(false)
      };
    return (
        <div className='paralax-component'>
            <div className="content-container">
                <div className="left-box">
                    <h3 className="title">Get In Touch</h3>
                    <p className="para">Leave your email. we'll get right back to you.</p>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <input
                            type="text"
                            name="email"
                            className="input-box"
                            placeholder="Enter Your Email Address"
                            {...register("email")}
                            required
                        />
                        <button type="submit" className='submit-btn'>{loader ? "Subscribe..." : "Subscribe"}</button>
                    </form>
                </div>
                <div className="right-box">
                    <img src={img1} alt="img1" className='img1' />
                </div>
            </div>
            <div className="thank-container">
                <h1 className="title">Thank You For Visiting Our Website</h1>
            </div>
        </div>
    )
}

export default ParalaxComonent