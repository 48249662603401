import React from 'react'
function HelmetContainer() {
    return (
        <>
            <div className="hide-text" style={{ display: 'none' }}>
                <h1>IT Company In Karimpur</h1>
                <h1>IT Company in Nadia</h1>
                <h1>IT Service In WestBengal</h1>
                <h1>IT Service Near Me Mumbai</h1>
                <h1>IT Service In Kolkata</h1>
                <h1>Best IT Service Near Me WestBengal</h1>
                <h1>Best IT Service Near Me Krishnagar</h1>
                <h1>Best IT Service Company In West Benagal</h1>
                <h1>Best IT Service In Baharampur</h1>
                <h1>IT Service In Durgapur</h1>
                <h1>Aspire World Tech Solutions -Best IT Service In West-Bengal</h1>
                <h1>Aspire World Tech Solutions -Best IT Service In Krishnagar</h1>
                <h1>Aspire World Tech Solutions -Best IT Service Baharampur</h1>
                <h1>Aspire World Tech Solutions -Best IT Service In Karimpur</h1>
                <h1>IT Service In West-Bengal</h1>
                <h1>Aspire World Tech Solutions - Tech Company In West-Bengal</h1>
                <h1>Aspire World Tech Solutions - Tech Company In India</h1>
                <a href="https://www.topdevelopers.co/directory/software-development-companies-in-west-bengal">topdevelopers</a>
                <a href="https://medium.com/web-development-zone/top-blogs-about-web-design-and-web-development-57706bb620be">medium</a>
                <a href="https://www.linkedin.com/posts/kolkata-it-hub_bengal-silicon-valley-expected-to-be-operational-activity-7051924841842540545-OFY8">linkedin</a>
                <a href="https://business.linkedin.com/">business</a>
                <a href="https://www.bloomberg.com/profile/company/0097338D:US">bloomberg</a>
                <a href="https://www.designrush.com/agency/it-services/california">designrush</a>
                <a href="https://clutch.co/it-services">clutch</a>
                <a href="https://careerfoundry.com/en/blog/web-development/">careerfoundry</a>
                <a href="https://forum.bubble.io/t/any-digital-marketing-companies-building-on-bubble/91718">forum</a>
                <a href="https://appinventiv.com/blog/newspaper-mobile-app-development-cost/">appinventiv</a>
                <a href="https://www.appfutura.com/forum/android-development/">appfutura</a>
                <a href="https://forum.vectric.com/viewtopic.php?t=28997">forum</a>
                <a href="https://www.aspiresoftware.com/our-companies/">aspiresoftware</a>
                <a href="https://techaquarius.com/">techaquarius</a>
                <a href="https://www.abclinuxu.cz/">abclinuxu</a>
                <a href="https://www.angelfire.lycos.com/">angelfire</a>
                <a href="https://codeigniter.com/">codeigniter</a>
                <a href="https://www.google.com/">google</a>
                <a href="https://ampblogs.com/">ampblogs</a>
                <a href="https://chamberphl.com/">chamberphl</a>
                <a href="https://www.turboitsolutions.com/">turboitsolutions</a>
                <a href="http://surfactants.net/">surfactants</a>
                <a href="https://turkishweekly.net/">turkishweekly</a>
                <a href="https://www.aiglobalmedialtd.com/">aiglobalmedialtd</a>
                <a href="https://www.upwork.com/resources/how-to-develop-an-app">upwork</a>
                <a href="https://business.adobe.com/blog/basics/digital-marketing">adobe</a>
                <a href="https://en.wikipedia.org/wiki/Digital_marketing">wikipedia</a>
            </div>
        </>
    )
}

export default HelmetContainer