import './TermsConditions.scss';

function TermsConditions() {
  return (
    <div className="terms-component">
      <div className="terms-container">
        <div className="terms-top-container">
          <h3 className="title">Terms & Conditions</h3>
          <p className="para">Aspire World Tech Solutions - Terms and Conditions</p>
        </div>

        <div className="terms-buttom-container">
          <ul>
            <li><span>Acceptance of Terms:</span> By accessing or using any services provided by Aspire World Tech Solutions, you agree to comply with and be bound by the following terms and conditions.</li>
            <li><span>Services:</span> Aspire World Tech Solutions offers a range of IT services. The specifics of each service are outlined in individual agreements. By engaging with our services, you acknowledge and accept the terms of the corresponding agreement.</li>
            <li><span>User Responsibilities:</span> Users are responsible for maintaining the confidentiality of their accounts, passwords, and any activities conducted under their account. Any unauthorized use must be reported immediately to Aspire World Tech Solutions.</li>
            <li><span>Payment and Billing:</span> Payment terms are detailed in the respective service agreements. Failure to make timely payments may result in the suspension or termination of services.</li>
            <li><span>Intellectual Property:</span> All intellectual property rights related to our services, including software, remain the property of Aspire World Tech Solutions. Users may not reproduce, distribute, or create derivative works without explicit permission.</li>
            <li><span>Confidentiality:</span> Both parties agree to treat confidential information with the utmost care and not disclose it to third parties. This obligation continues even after the termination of services.</li>
            <li><span>Data Security:</span> Aspire World Tech Solutions takes reasonable measures to protect client data. However, clients are responsible for the security of their own data and should implement appropriate measures on their end.</li>
            <li><span>Termination:</span> Either party may terminate services with written notice. Upon termination, clients are responsible for outstanding fees up to the termination date.</li>
            <li><span>Limitation of Liability:</span> Aspire World Tech Solutions is not liable for any indirect, incidental, special, or consequential damages. Our liability is limited to the amount paid for the specific services causing the damage.</li>
            <li><span>Updates and Changes:</span> Aspire World Tech Solutions reserves the right to update and modify these terms and conditions. Users will be notified of significant changes, and continued use of services constitutes acceptance of the updated terms.</li>
          </ul>
        </div>

      </div>
    </div>
  )
}

export default TermsConditions