



export const NabItems = [
    {
        name : "Home",
        link : "#home",
        href : "#home",
    },
    {
        name : "About",
        link : "#about",
        href : "#about",
    },
    {
        name : "Service",
        link : "#service",
        href : "#service",
    },
    {
        name : "Projects",
        link : "#projects",
        href : "#projects",
    },
    {
        name : "Reviews",
        link : "#reviews",
        href : "#reviews",
    },
    {
        name : "Contact",
        link : "#contact",
        href : "#contact",
    }
]