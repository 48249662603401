import Carousel from 'react-bootstrap/Carousel';
import { FaQuoteRight } from "react-icons/fa";
import subhendu from '../../Assates/Reviews/subhendu.jpg';
import JackSmethem from '../../Assates/Reviews/Jack Smethem.jpg';
import Soumojit from '../../Assates/Reviews/soumojit.jpg';
import debasish from '../../Assates/Reviews/debasish.jpg'
import { FaRegStar } from "react-icons/fa";

function ReviewSlider() {
    return (
        <Carousel>
            <Carousel.Item>
                <div className="review-item-box">
                    <div className="inner-item-box">
                        <img src={subhendu} alt="aspire world tech review by energic crop seinece" className='client-image' />
                        <div className="contentbox">
                            <p className="paragrapg">We engaged with Aspire World Tech Solutions for our IT infrastructure overhaul, and the results were nothing short of exceptional. The team's technical prowess and commitment to understanding our unique needs were evident throughout the process. They delivered a robust solution that significantly improved our efficiency. Highly recommend their services!</p>
                            <h3 className="title">Subhendu Dey</h3>
                            <p className="para">Energic Crop Scinece</p>
                            <p className="para">Manager</p>
                        </div>
                        <FaQuoteRight className='quots' />
                        <FaRegStar className='stars' />
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="review-item-box">
                    <div className="inner-item-box">
                        <img src={Soumojit} alt="aspire world tech review by sudhir bastralaya" className='client-image' />
                        <div className="contentbox">
                            <p className="paragrapg">Our experience with Aspire World Tech Solutions has been fantastic. From prompt issue resolution to proactive maintenance, their support team ensures our systems run smoothly. Their attention to detail and quick response time have made them an invaluable part of our tech strategy. Highly recommended for reliable tech support!</p>
                            <h3 className="title">Soumyajit Ghosh</h3>
                            <p className="para">Sudhir Bastralaya</p>
                            <p className="para">Manager</p>
                        </div>
                        <FaQuoteRight className='quots' />
                        <FaRegStar className='stars' />
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="review-item-box">
                    <div className="inner-item-box">
                        <img src={debasish} alt="aspire world tech review by new sunity jewellers" className='client-image' />
                        <div className="contentbox">
                            <p className="paragrapg">Aspire World Tech Solutions impressed us with their ability to create customized tech solutions that perfectly align with our business goals. The team's creativity and attention to detail set them apart. They not only met but exceeded our expectations. Looking forward to continued collaboration on future projects!</p>
                            <h3 className="title">Debasish Karmakar</h3>
                            <p className="para">New Sunity Jeweller's</p>
                            <p className="para">Owner</p>
                        </div>
                        <FaQuoteRight className='quots' />
                        <FaRegStar className='stars' />
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    )
}

export default ReviewSlider