import './Clients.scss'
import sb from '../../Assates/Client/sb.png';
import nsj from '../../Assates/Client/nsj.png';
import rd from '../../Assates/Client/rana.png'
import ecs from '../../Assates/Client/LOGO.png';
import ma from '../../Assates/Client/ma.png';
import k from '../../Assates/Client/k.png';
// import skill from '../../Assates/Client/skill.svg';

function Clients() {
  return (
    <div className='clients-component'>
      <div className="clients-container">
        <img src={sb} alt="aspire world clients- sudhir bastralaya" className='clients-logo'/>
        <img src={ecs} alt="aspire world clients- energic crop science" className='clients-logo'/>
        {/* <img src={skill} alt="aspire world clients- GrowSkill" className='clients-logo'/> */}
        <img src={ma} alt="aspire world clients- maa farmecy" className='clients-logo'/>
        <img src={k} alt="aspire world clients - kanailal pathology" className='clients-logo'/>
        <img src={rd} alt="aspire world clients - ranaditya helth care" className='clients-logo'/>
        <img src={nsj} alt="aspire world clients - new sunity jewellerys" className='clients-logo'/>
      </div>
    </div>
  )
}

export default Clients