

import logo from '../Assates/Logo/brandlogo.png'

export const FooterItem = {
    brand : "Aspire World Tech Solutions",
    logo : logo,
    address : 'F-57 RMC Building, Karimpur, Nadia, West Bengal, India, Pin-741122',
    phone : "9933418310",
    instaLink : "https://www.instagram.com/aspireworldtechsolutions/",
    fbLink : "https://www.facebook.com/profile.php?id=61554466493920",
    whatsappLink : "https://wa.me/send?phone=9933418310&text=Hello,Team Aspire World Tech Solutions",
    twtLink :"https://twitter.com/AWT_Solutions",
}