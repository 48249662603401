import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import LandingPage from "./LandingPage/LandingPage";
import TermsConditionsPage from "./Pages/TermsConditionsPage";
import PageNotFound from "./PageNotFound/PageNotFound";
import HelmetContainer from "./Helmet/HelmetContainer";
// import Popup from "./Components/Popup/Popup";
export const GlobalStorage = createContext();

function App() {
  const [pageName, setPageName] = useState("Home");
  // const [popup, setPopup] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setPopup(true);
  //   }, 5000);
  // }, []);

  return (
    <div className="App">
      <GlobalStorage.Provider
        value={{
          //send data
          pageName: pageName,
          //recived data
          setPageName: setPageName,
        }}
      >
        <BrowserRouter>
          <HelmetContainer />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/terms-and-conditions" element={<TermsConditionsPage />}/>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          {/* {popup ? <Popup setPopup={setPopup}/> : null} */}
        </BrowserRouter>
      </GlobalStorage.Provider>
    </div>
  );
}

export default App;
