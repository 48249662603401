import './Service.scss';
import { CgWebsite } from "react-icons/cg";
import { MdAppShortcut } from "react-icons/md";
import { RiSeoLine } from "react-icons/ri";
import { FaChartArea } from "react-icons/fa";
import { FaCircleQuestion } from "react-icons/fa6";
import { FaChartPie } from "react-icons/fa";




function Service() {
  return (
    <div className="service-component" id='service'>
      <div className="service-container">
        <div className="service-top-container">
          <p className="head">Service</p>
          <h3 className="tags">The Best Quality Service for You</h3>
          <p className="para">Aspire world tech solutions is a Leading IT company in India specializing in website and app development, digital marketing, UI/UX design,SEO, and business ideas.</p>
        </div>

        <div className="service-buttom-container">

          <div className="service-item-box">
            <div className="icon-box">
              <CgWebsite className='icon' />
            </div>
            <h3 className="head">Website Development</h3>
            <p className="para">Customized Web Development using Open Source Technologies in B2C and B2B Environment.</p>
          </div>

          <div className="service-item-box">
            <div className="icon-box">
              <MdAppShortcut className='icon' />
            </div>
            <h3 className="head">App Development</h3>
            <p className="para">Customized & Interactive Mobile Application development in Android and Iphone environment.</p>
          </div>

          <div className="service-item-box">
            <div className="icon-box">
              <RiSeoLine className='icon' />
            </div>
            <h3 className="head">SEO</h3>
            <p className="para">Creative Branding with Search Engine Optimization, Social Media Marketing and Digital Media Growth.</p>
          </div>

          <div className="service-item-box">
            <div className="icon-box">
              <FaChartArea className='icon' />
            </div>
            <h3 className="head">UI/UX Design</h3>
            <p className="para">Customized Web Design using Photoshop & Figma Technologies in B2C and B2B Environment.</p>
          </div>

          <div className="service-item-box">
            <div className="icon-box">
              <FaChartPie className='icon' />
            </div>
            <h3 className="head">Digital Marketing</h3>
            <p className="para"> We specialize in crafting impactful digital marketing campaigns that transcend conventional boundaries. </p>
          </div>

          <div className="service-item-box">
            <div className="icon-box">
              <FaCircleQuestion className='icon' />
            </div>
            <h3 className="head">Business Idea</h3>
            <p className="para">Our mission is to empower individuals and businesses alike by providing Key Benefits or Features.  </p>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Service