import './Review.scss';
import ReviewSlider from '../../Components/Slider/ReviewSlider';

function Review() {
  return (
    <div className="review-component" id='reviews'>
      <div className="review-container">
        <div className="review-top-container">
          <p className="head">Reviews</p>
          <h3 className="tags">What Our Clients Say About Us</h3>
          <p className="para">Few of our Client's Feedback</p>
        </div>
        <div className="review-buttom-container">
          <ReviewSlider />
        </div>
      </div>
    </div>
  )
}

export default Review