import AppBar from '../Components/Navbar/AppBar';
import HomePage from '../Pages/HomePage';
import ClientsPage from '../Pages/ClientsPage';
import AboutPage from '../Pages/AboutPage';
import ParalaxComonent from '../Components/Paralax/ParalaxComonent';
import ContactPage from '../Pages/ContactPage';
import ProjectPage from '../Pages/ProjectPage';
import ReviewPage from '../Pages/ReviewPage';
import ServicePage from '../Pages/ServicePage';
import Footer from '../Components/Footer/Footer';

function LandingPage() {
    return (
        <>
            <AppBar />
            <HomePage />
            <ClientsPage/>
            <AboutPage />
            <ServicePage />
            <ParalaxComonent/>
            <ProjectPage />
            {/* <TeamsPage /> */}
            <ReviewPage />
            <ContactPage />
            <Footer />
        </>
    )
}
export default LandingPage;