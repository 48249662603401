import "./Footer.scss";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FooterItem } from "../../Datas/Footer";
import Swal from "sweetalert2";
import { useContext, useState } from "react";
import { GlobalStorage } from "../../App";
import { addNewsletterEmail } from "../../Firebase/firebase";

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email is a required field")
      .email("Must be a valid email"),
  })
  .required();

function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  const [spinner, setSpinner] = useState(false);
  const { setPageName } = useContext(GlobalStorage);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    setSpinner(true);
    const result = await addNewsletterEmail(data.email);
    if (result.success) {
      Swal.fire({
        title: "Thank You",
        text: result.message,
        icon: "success",
      });
      reset();
    } else {
      Swal.fire({
        title: "Opps... Sorry!",
        text: result.message,
        icon: "error",
      });
    }
    setSpinner(false);
  };
  return (
    <div className="footer-components">
      <div className="footer-container">
        <div className="left-container">
          <div className="brand">
            <img src={FooterItem.logo} alt="sudhir" className="logo" />
          </div>

          <div className="details">
            <div className="address">
              <div className="items">
                <p className="head">Address : </p>
                <p className="pera">{FooterItem.address}</p>
              </div>
              <div className="items">
                <p className="head">Phone : </p>
                <p className="pera">{FooterItem.phone}</p>
              </div>
            </div>
            <div className="logos">
              <a href={FooterItem.fbLink} target="_blank" rel="noreferrer">
                <BsFacebook className="icons" />
              </a>
              <a href={FooterItem.instaLink} target="_blank" rel="noreferrer">
                <BsInstagram className="icons" />
              </a>
              <a
                href={FooterItem.whatsappLink}
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp className="icons" />
              </a>
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="left">
            <div className="about-us">
              <table>
                <tr>
                  <th>Quick Links</th>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <a href="#home" onClick={() => setPageName("Home")}>
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="#about" onClick={() => setPageName("About")}>
                          About
                        </a>
                      </li>
                      <li>
                        <a href="#team" onClick={() => setPageName("Team")}>
                          Team
                        </a>
                      </li>
                      <li>
                        <a
                          href="#reviews"
                          onClick={() => setPageName("Reviews")}
                        >
                          Reviews
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </div>
            <div className="about-us">
              <table>
                <tr>
                  <th>Quick Links</th>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <a
                          href="#service"
                          onClick={() => setPageName("Service")}
                        >
                          Service
                        </a>
                      </li>
                      <li>
                        <a
                          href="#projects"
                          onClick={() => setPageName("Projects")}
                        >
                          Projects
                        </a>
                      </li>
                      <li>
                        <a
                          href="#contact"
                          onClick={() => setPageName("Contact")}
                        >
                          Contact
                        </a>
                      </li>
                      <li>
                        <a href="/terms-and-conditions" target="_blank">
                          Terms & Conditions
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="right">
            <div className="subscribe">
              <table>
                <tr>
                  <th>Subscribe</th>
                </tr>
              </table>
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <label>YOUR EMAIL</label>
                <div className="input">
                  <input
                    type="text"
                    name="email"
                    className="input-box"
                    placeholder="Enter Your Email Address"
                    {...register("email")}
                  />
                  <p>{errors.email?.message}</p>
                </div>
                <button type="submit" className="submit-btn">
                  {spinner ? "Subscribe..." : "Subscribe"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-box">
        <div className="inner-box">
          <p>@{year} Aspire World Tech Solutions All Copy Right Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
