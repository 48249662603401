import './Home.scss';
import 'animate.css';
import backgroundImg from '../../Assates/Element/hero-img.png';

function Home() {
  return (
    <div className="home-component" id="home">
      <div className="home-container">
        <div className="left-container  ">
          <h1 className="title animate__animated animate__fadeInDown animate__delay-2s">Your Gateway To Digital Innovation</h1>
          <h3 className="tags animate__animated animate__fadeInDown animate__delay-1s">Website Development, App Development, SEO, Digital Marketing, UI/UX Design.</h3>
          <a href='#service' className="lernmore-btn animate__animated animate__fadeInDown ">Learn More</a>
        </div>
        <div className="right-container  animate__animated  animate__fadeInRight">
          <img src={backgroundImg} alt="aspire world tech solutions - tech company in india" className='img'/>
        </div>
      </div>
    </div>
  )
}

export default Home