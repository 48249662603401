import "./Contact.scss";
import React, { useState } from "react";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import { IoLocationSharp, IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { BsFacebook } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import followimg from "../../Assates/Element/followus.png";
import Swal from "sweetalert2";
import { FooterItem } from "../../Datas/Footer";
import { newContacts } from "../../Firebase/firebase";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Name is a required field")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must be at most 50 characters"),
    email: yup
      .string()
      .required("Email is a required field")
      .email("Must be a valid email"),
    number: yup
      .string()
      .required("Number is a required field")
      .matches(/^[0-9]+$/, "Number must be only digits")
      .min(10, "Number must be at least 10 digits")
      .max(12, "Number must be at most 12 digits"),
    message: yup
      .string()
      .required("Message is a required field")
      .min(10, "Message must be at least 10 characters")
      .max(500, "Message must be at most 500 characters"),
    newsletter: yup
      .boolean()
      .notRequired()
      .oneOf([true, false], "Newsletter must be true or false"),
  })
  .required();

function Contact() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    setLoading(true);
    const result = await newContacts(data);
    if (result.success) {
      Swal.fire({
        icon: "success",
        title: "Thank You!",
        text: result.message,
      });
      reset();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops... Sorry!",
        text: result.message,
      });
    }
    setLoading(false);
  };

  return (
    <div className="contact-component" id="contact">
      <div className="contact-container">
        <div className="left-box">
          <p className="heads">Contact</p>
          <h3 className="headers">Get In Touch</h3>
          <p className="para">
            Leave your name, email, number and message we'll <br /> get right
            back to you.
          </p>

          <div className="details-box">
            <div className="items">
              <IoLocationSharp className="icons" />
              <div className="right">
                <p className="head">Location</p>
                <p className="tag">
                  F-57 RMC Building, Karimpur,
                  <br /> Nadia, West Bengal, Pin- 741152.
                </p>
              </div>
            </div>

            <div className="items">
              <IoCall className="icons" />
              <div className="right">
                <p className="head">Call Us</p>
                <p className="tag">+91 9933418310</p>
              </div>
            </div>

            <div className="items">
              <MdEmail className="icons" />
              <div className="right">
                <p className="head">Email</p>
                <p className="tag">support@aspireworldtechsolutions.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mid-box">
          <img
            src={followimg}
            alt="IT Service $ Solutions in Delhi"
            className="followimg"
          />
          <div className="icons-link">
            <a href={FooterItem.fbLink} target="blank">
              <BsFacebook className="icon" />
            </a>
            <a href={FooterItem.instaLink} target="blank">
              <AiFillInstagram className="icon" />
            </a>
            <a href={FooterItem.twtLink} target="blank">
              <AiFillTwitterCircle className="icon" />
            </a>
          </div>
        </div>
        <form className="right-box" onSubmit={handleSubmit(onSubmit)}>
          <h3 className="heads">Let's Connect</h3>
          <div className="item-group">
            <input
              type="text"
              className="input"
              placeholder="Your Name"
              name="name"
              {...register("name")}
            />
            <input
              type="email"
              className="input"
              placeholder="Your Email"
              name="email"
              {...register("email")}
            />
          </div>

          <input
            type="number"
            className="input-box number"
            placeholder="Your Number"
            name="number"
            {...register("number")}
          />

          <textarea
            className="message"
            placeholder="How can i help?"
            name="message"
            {...register("message")}
          ></textarea>
          {errors.name ? <p className="errorP">*{errors.name?.message}</p> : errors.email ? <p className="errorP">*{errors.email?.message}</p> : errors.number ? <p className="errorP">*{errors.number?.message}</p> : errors.message ? <p className="errorP">*{errors.message?.message}</p> : null}
          <div className="buttom-box">
            <div className="input-field-3">
              <label>
                <input
                  type="checkbox"
                  name="newsletter"
                  {...register("newsletter")}
                />
                I want to recieve newsletters
              </label>
            </div>
            {loading ? (
              <button className="button-loder">
                Sending
                {
                  <ThreeDots
                    radius="3"
                    color="#fff"
                    visible={true}
                    height="20"
                    width="50"
                  />
                }
              </button>
            ) : (
              <button className="button send-massage">Send Message</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
