import './AppBar.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../../Assates/Logo/brandlogo.png';
import { NabItems } from "../../Datas/UserNavbar";
import {useContext} from 'react';
import { GlobalStorage } from '../../App';


function AppBar() {
    const {pageName, setPageName} = useContext(GlobalStorage);
    const expand = "xl";
    return (
        <Navbar key={expand} expand={expand} className={`"bg-body-tertiary mb-3 navbar`}>
            <Container fluid className="box-container">
                <Navbar.Brand href="/" className='brand'>
                    <img src={logo} alt="logo" className='logo' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className='menu-btn' />
                <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end" className="mobile-navigation">
                    <Offcanvas.Header closeButton className='mobile-nav' >
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='nav-head-box'>
                            <img src={logo} alt="logo" className='logo' />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3 items-box">
                            {
                                NabItems.map((item, index) => (
                                    <Nav.Link href={item.link} className={pageName === item.name ? "focus-item" : 'href-item'} key={index} id={item.name} onClick={() => setPageName(item.name)}>{item.name}</Nav.Link>
                                ))
                            }
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default AppBar