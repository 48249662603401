import './About.scss';
import aboutimg from "../../Assates/Element/why-us.png";
import backImg from "../../Assates/Element/skills.png";
import boosticon from "../../Assates/Icon/boost.png";
import clockicon from "../../Assates/Icon/clock.png";
import skills from "../../Assates/Icon/skill.png";

function About() {
  return (
    <div className="about-component" id='about'>

      <div className="about-container">

        <div className="mid-container-box">
          <div className="left">
            <img src={aboutimg} alt="it service & solutions company in india, " className="aboutimg" />
          </div>
          <div className="right">
            <p className="head">About Us</p>
            <h3 className="tags">
              About The <br />
              Aspire World Tech Solutions
            </h3>
            <p className="para">
              Aspire World Tech Solutions is the one stop solution for all your website design and  development needs. We have a team of highly trained and experienced professionals that can turn your dream digital product into a reality. We make industry standard Websites & Apps so that your customers feel at home & the best part is, this doesn't hurt your pocket.
            </p>
            <p className="para">
              At Aspire World Tech Solutions, our passion for technology drives us to deliver outstanding results for our clients. We are not just a tech team, we are your trusted digital partner committed to pushing the boundaries of digital excellence. Join us on this exciting journey and let's transform your digital aspirations into reality with Aspire World Tech Solutions.
            </p>
          </div>
        </div>

        <div className="sec-mid-container">
          <div className="left">
            <h3 className="head">The History of <br /> Aspire World Tech Solutions</h3>
            <div className="item-box-container">
              <div className="item-box">
                <div className="left">
                  <img src={boosticon} alt="technology solutions provider at kolkata" className="icon" />
                </div>
                <div className="right">
                  <h5 className="head">Since our inception:</h5>
                  <p className="para">we have grown steadily, expanding our operations and
                    building a reputation for excellence in the industry.
                    Through strategic partnerships and collaborations.
                  </p>
                </div>
              </div>

              <div className="item-box">
                <div className="left">
                  <img src={clockicon} alt="clock icon" className="icon" />
                </div>
                <div className="right">
                  <h5 className="head">Over the years:</h5>
                  <p className="para">
                    we have continually invested in training and equipping our
                    team with the latest tools and technologies, ensuring we
                    stay at the forefront of the industry.
                  </p>
                </div>
              </div>

              <div className="item-box">
                <div className="left">
                  <img src={skills} alt="it service & solutions Company in west-bengal" className="icon" />
                </div>
                <div className="right">
                  <h5 className="head">As we continue to grow:</h5>
                  <p className="para">
                    we remain focused on our core values of trust, reliability,
                    and customer satisfaction. We are proud of the relationships
                    we have built with our customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="right-c">
            <img src={backImg} alt="leading it solutions company in india" className="imgssss" />
          </div>
        </div>

      </div>
    </div>
  )
}

export default About